import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import ReactPlayer from 'react-player';
import { Box, Flex, Text } from 'rebass';
import { Select } from '@rebass/forms';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import * as detectBrowser from 'detect-browser';

import PlayButton from './PlayButton';
import Spinner from './Spinner';
import { streams } from '../shared/config';
import NotificationBar from './NotificationBar';

const IS_DEV = true;

const SYNC_CHECK = true;
const NO_PLAY_SRC = '';

const browser = detectBrowser.detect();
const { name: bName = '' } = browser;
const isIOS = bName === 'ios';

let streamOldTime = 0;
const streamCheckRateMs = 7 * 1000;
let streamCheckInterval;

function resetStreamCheck() {
  streamOldTime = 0;
  clearInterval(streamCheckInterval);
  if (IS_DEV) console.log('Stream interval check reset.');
}

function PrivateRadioPlayer() {
  const [shouldPlay, setShouldPlay] = useState(false);
  const [isRadioStreaming, setIsRadioMediaStreaming] = useState(false);
  const [stream, setStream] = useState(streams[0]);
  const [isError, setIsError] = useState(false);
  const playerRef = useRef(null);

  const actualUrl = (shouldPlay) ? stream.url : NO_PLAY_SRC;

  // Fix Icecast's stream stopping after reset audio source (malformed headers in the stream)
  useEffect(() => {
    const playerCurrent = playerRef.current;

    if (SYNC_CHECK && shouldPlay && playerCurrent != null && isRadioStreaming) {
      try {
        // Disable in Safari - because of iOS webkit problem
        if (isIOS) return () => {};

        if (IS_DEV) console.log('Sync: Rebuild interval check');

        streamCheckInterval = setInterval(() => {
          const streamTime = Math.floor(playerCurrent.getCurrentTime());
          const diff = streamTime - streamOldTime;

          if (diff === 0) {
            if (IS_DEV) console.log('Sync: Resyncing ', { streamTime, streamOldTime });
            const player = playerCurrent.getInternalPlayer();
            if (player) {
              player.pause();
              player.currentTime = 0;
              player.src = actualUrl;
              player.play().catch((err) => {
                console.warn(err);
              });
            } else if (IS_DEV) console.error("Sync: Can't obtain current player.");
          } else if (IS_DEV) console.log(`Sync: OK ${diff}`);
          // console.log(streamTime, streamOldTime);
          streamOldTime = streamTime;
        }, streamCheckRateMs);
      } catch (e) {
        if (IS_DEV) console.error(e);
      }
      // if (IS_DEV) console.log('Radio streaming', playerRef, streamCheckInterval);
    }

    return () => resetStreamCheck();
  }, [isRadioStreaming, playerRef, shouldPlay, actualUrl]);

  const handleOnPlay = useCallback(() => {
    setIsRadioMediaStreaming(true);
  });

  const handlePlayerError = useCallback((e) => {
    setIsError(true);
    console.error(e);
  }, [setIsError]);

  // We the audio player when not playing because we don't want audio to keep buffering in the
  // background. The desired behaviour is that when we start playing - it should play a current
  // audio stream (like in real radio)

  const handlePlayPauseClicked = useCallback(() => {
    setIsError(false);
    if (shouldPlay) {
      setShouldPlay(false);
      setIsRadioMediaStreaming(false);
    } else {
      setShouldPlay(true);
    }
  }, [shouldPlay]);

  if (!shouldPlay) {
    if (playerRef.current != null) {
      const player = playerRef.current.getInternalPlayer();
      if (player) {
        player.currentTime = 0;
        player.pause();
        player.src = NO_PLAY_SRC;
        player.load();
      }
    }
  }

  const changeStreamUrl = useCallback((v) => {
    const { value } = v.target;
    const newStream = streams.find((x) => x.label === value);
    if (!newStream) {
      console.log('Error changed to unknown stream.', value);
      return;
    }
    setIsError(false);
    setShouldPlay(false);
    setIsRadioMediaStreaming(false);
    resetStreamCheck();
    setStream(newStream);
    console.log('Changing stream to:', value);
  }, []);

  const title = `Privado - ${stream.label}`;

  const selectBox = React.useRef(null);

  return (
    <Box>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Flex justifyContent="center" mt={3} alignItems="center">
        <Select
          width={200}
          id="lang"
          my={2}
          mr={[3, 4]}
          name="lang"
          defaultValue="Español"
          ref={selectBox}
          onChange={(e) => {
            selectBox.current.blur();
            changeStreamUrl(e);
          }}
          sx={{
            borderRadius: '4px',
            borderWidth: '2px',
            fontWeight: 'bold',
            cursor: 'pointer',
            boxShadow: 'primary',
            transition: '0.2s',
            '+ svg': {
              transition: '0.2s',
            },
            '&:focus': {
              outline: '1px solid',
              outlineColor: 'secondary',
            },
            '&:focus, &:hover': {
              color: 'secondary',
              borderColor: 'secondary',
              transform: 'translateY(-2px)',
              '+ svg': {
                color: 'secondary',
                transform: 'translateY(-2px)',
              },
            },
            '&:hover': {
              boxShadow: 'menuHover',
            },
          }}
        >
          {streams.map((u) => <option key={u.label} value={u.label}>{u.label}</option>)}
        </Select>
        <StyledPlayButton onClick={handlePlayPauseClicked} isPlaying={shouldPlay} />

      </Flex>


      <Flex justifyContent="center" mt={3} alignItems="center">
        {shouldPlay && !isRadioStreaming && <Spinner />}
      </Flex>
      <ReactPlayer
        ref={playerRef}
        url={actualUrl}
        style={{ display: 'none' }}
        playing={shouldPlay}
        controls
        onPlay={handleOnPlay}
        onError={handlePlayerError}
        config={{
          file: {
            forceAudio: true,
            attributes: {
              id: 'audio-element',
              // crossOrigin: 'use-credentials',
            },
          },
        }}
      />
      {isError
      && (
        <NotificationBar mx="auto" width={[3 / 3, null, null, 1]}>
          <Text fontFamily="body" sx={{ overflowWrap: 'break-word' }}>
            Si no funciona el audio, haga clic
            {' '}
            <a href={actualUrl} style={{ overflowWrap: 'break-word' }}>aquí.</a>
          </Text>
          <br />

          <Text fontFamily="body" sx={{ overflowWrap: 'break-word' }}>
            If you can't hear any sound, please click
            {' '}
            <a href={actualUrl} style={{ overflowWrap: 'break-word' }}>here.</a>
          </Text>
        </NotificationBar>
      )}
    </Box>
  );
}

const StyledPlayButton = styled((props) => (
  <PlayButton
    id="RadioPlayButton"
    onClick={props.onClick}
    isPlaying={props.isPlaying}
    my={3}
  />
))``;

export default PrivateRadioPlayer;
