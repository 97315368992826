import { HubConnectionBuilder } from '@microsoft/signalr';
import { isDev } from './config';

const url = isDev ? 'http://localhost:5000/privadoHub' : 'https://transmision.radiomaitreya.org/privadoHub';

export default class PrivadoHub {
  constructor() {
    this.connection = new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect([0, 2000, 10000, 30000, 60000])
      .build();
  }
}
