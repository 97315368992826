/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
  Image, Flex, Heading,
} from 'rebass';
import RadioPlayer from '../components/PrivateRadioPlayer';
import logo from '../images/logo_maitreya.svg';
import NotificationBar from '../components/NotificationBar';
import BackgroundImage from '../components/BackgroundImage';
import PrivadoHub from '../shared/privadoHub';


export default function Main() {
  const [connection, setConnection] = useState(null);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    try {
      const privadoHub = new PrivadoHub();
      setConnection(privadoHub.connection);
    } catch (e) {
      console.error('Can\'t set connection');
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (connection) {
      connection.start().then(() => {
        console.log('Connected!');

        connection.on('PubNotification', (msg) => {
          setNotification(msg);
        });
      }).catch((e) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  return (
    <Flex
      height="100vh"
      width="100%"
      mx="auto"
      flexDirection="column"
      alignItems="center"
    >
      <BackgroundImage />

      <Flex
        id="InnerContent"
        width={['100%', null, null, 4 / 5]}
        height="90%"
        my="auto"
        flexDirection={['column', null, null, 'row-reverse']}
        sx={{
          zIndex: 3,
        }}
      >
        <Flex
          width={['100%', null, null, 1 / 2]}
          alignItems="center"
          height={['40%', null, null, '100%']}
        >
          <LogoImage />
        </Flex>

        <Flex width={['100%', null, null, 1 / 3]} justifyContent="center" flexDirection="column">
          <Flex width={1} mt={3} justifyContent="center">
            <Heading as="h1" fontFamily="body" fontSize={[35, 50]} textAlign="center">
              Privado
            </Heading>
          </Flex>
          {notification
          && <NotificationBar mx="auto" width={[2 / 3, null, null, 1]} notification={notification} />}
          <Flex width={1} justifyContent="center">
            <RadioPlayer />
          </Flex>
        </Flex>

      </Flex>
    </Flex>
  );
}

const LogoImage = () => (
  <Image
    mx="auto"
    height="182px"
    src={logo}
    width={['80%', '50%']}
    mb={[2, null, null, 5]}
  />
);
