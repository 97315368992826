// eslint-disable-next-line import/prefer-default-export
export const streams = [
  { label: 'Español', url: 'https://stream.radiomaitreya.org:8443/radio_maitreya_private' },
  { label: 'English', url: 'https://stream.radiomaitreya.org:8443/radio_maitreya_english_private' },
  { label: 'Français', url: 'https://stream.radiomaitreya.org:8443/radio_maitreya_french_private' },
  { label: 'Português', url: 'https://stream.radiomaitreya.org:8443/radio_maitreya_portuguese_private' },
  { label: 'Română', url: 'https://stream.radiomaitreya.org:8443/radio_maitreya_romanian_private' },
  { label: 'Magyar', url: 'https://stream.radiomaitreya.org:8443/radio_maitreya_hungarian_private' },
  { label: 'Монгол', url: 'https://stream.radiomaitreya.org:8443/radio_maitreya_mongolian_private' },
  { label: 'Italiano', url: 'https://stream.radiomaitreya.org:8443/radio_maitreya_italian_private' },
  { label: 'Pусский', url: 'https://stream.radiomaitreya.org:8443/radio_maitreya_russian_private' },
];


export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
