import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import Main from './Main';
import theme from '../theme';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Main />
    </ThemeProvider>
  );
}
