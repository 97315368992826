export const mobileQuery = 'screen and (max-width: 1365px)';

export default {
  breakpoints: ['576px', '768px', '1366px'],
  fontSizes: [
    '0.75rem', '0.875rem', '1rem', '1.125rem', '1.25rem', '1.5rem', '2rem', '3rem', '4rem',
  ],
  colors: {
    primary: '#962733',
    secondary: '#f7941e',
    terciary: '#f9f5f5',
    quarternary: '#6E262E',
    blue: '#07c',
    lightgray: '#f6f6ff',
    textPrimary: '#595959',
    lightGrey: '#ccc',
    breadCrumbs: {
      link: '#6e6e6e',
      linkHover: '#f7941e',
      currentPage: '#b2b2b2',
    },
    forms: {
      input: {
        border: '#7f7f7f',
        placeholder: '#c4c4c4',
      },
    },
    textLightPrimary: '#ffffff',
    textHeadingPrimary: '#962733',
    backgroundGradient: `linear-gradient(
      0deg,
      rgba(229, 229, 229, 1) 0%,
      rgba(255, 255, 255, 1) 30%,
      rgba(255, 255, 255, 1) 76%,
      rgba(229, 229, 229, 1) 100%
    )`,
  },
  space: [
    0, 4, 8, 16, 32, 64, 128, 256,
  ],
  fonts: {
    body: '"Open Sans", sans-serif',
    heading: '"Source Sans Pro", sans-serif',
    primaryFont: '"Open Sans", sans-serif',
    secondaryFont: '"Source Sans Pro", sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    light: 300,
    body: 400,
    medium: 500,
    navLink: 600,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    primary: '0px 0px 17px rgba(0, 0, 0, 0.15)',
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
    menuHover: '0 0 8px rgba(0,0,0,.6)',
    menu: '0 0 8px rgba(0,0,0,.8)',
  },
  forms: {
    button: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '41px',
      backgroundColor: 'white',
      color: 'forms.input.border',
      borderColor: 'forms.input.border',
    },
    input: {
      borderWidth: '1px',
      borderColor: 'forms.input.border',
      borderRadius: '3px',
      padding: 2,
      color: 'forms.input.placeholder',
    },
    textarea: {
      borderWidth: '1px',
      borderColor: 'forms.input.border',
      borderRadius: '3px',
      padding: 2,
      color: 'forms.input.placeholder',
    },
  },
  variants: {
    lines: {
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      height: '0.25rem',
      width: '100%',
      backgroundColor: 'secondary',
    },
    cards: {
      elevatedRounded: {
        boxShadow: 'primary',
        borderRadius: '6px',
        background: 'white',
        margin: 2,

        tabList: {
          backgroundColor: 'terciary',
          display: 'flex',
          flexDirection: 'row',
          padding: 2,
          paddingLeft: 3,
        },
        tab: {
          display: 'flex',
          flexDirection: 'row',
          color: 'primary',
          fontSize: 0,
          padding: 1,
          textTransform: 'uppercase',

          marginRight: 3,
          fontWeight: 'bold',
          cursor: 'pointer',
        },
        tabs: {
          backgroundColor: 'white',
          boxShadow: 'primary',
          borderRadius: '6px',
          margin: 1,
        },
        tabsPanel: {
          padding: 3,
        },
        bottomPanel: {
          backgroundColor: 'white',
        },
      },
    },
  },
  text: {
    textPrimary: '#595959',
    textLightPrimary: '#ffffff',
    textHeadingPrimary: '#962733',

    blockBackground: '#ffffff',

    languageButton: {
      display: 'flex',
      flexDirection: 'row',
      color: 'primary',
      fontSize: 0,
      padding: 1,
      textTransform: 'uppercase',

      marginRight: [0, null, null, 3],
      fontWeight: 'bold',
      cursor: 'pointer',
    },

    programme: {
      time: {
        color: 'primary',
        fontWeight: 'heading',
        fontSize: 0,
        textAlign: 'center',
      },
      artist: {
        color: 'secondary',
        fontWeight: 'body',
        textTransform: 'uppercase',
        fontSize: [0, 1],
      },
      title: {
        fontWeight: 'body',
        fontSize: [0, 1],
        color: 'tertiary',
      },
    },

    navLink: {
      a: {
        color: ['secondary', null, null, 'primary'],
        '&:hover': {
          color: ['terciary', null, null, 'secondary'],
        },
        '&.active': {
          paddingBottom: '3px',
          borderBottom: '1px solid',
          color: ['terciary', null, null, 'secondary'],
          borderColor: ['terciary', null, null, 'secondary'],
          '&:hover': {
            borderColor: ['terciary', null, null, 'secondary'],
          },
        },
      },
      textAlign: ['left', null, null, 'center'],
      fontSize: [3, null, null, 0],
      fontWeight: ['bold', 'navLink'],
      lineHeight: '1rem',
      textTransform: 'uppercase',
      transition: '0.5s',
    },
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
    },
  },
};
