/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import { Box, Text } from 'rebass';

export default function NotificationBar({ notification, children, ...props }) {

  return (
    <OuterBanner
      // eslint-disable-next-line
      {...props} id="OuterBanner" textAlign="center">
      <InnerBanner sx={{}}>
        {notification
          ? (
            <Text fontFamily="body" sx={{ overflowWrap: 'break-word' }}>
              {notification}
            </Text>
          )
          : children}
      </InnerBanner>
    </OuterBanner>
  );
}

const OuterBanner = styled(Box)``;

const InnerBanner = styled((props) => (
  <Box
    // eslint-disable-next-line
    {...props}
    sx={{
      borderRadius: '4px',
      color: 'black',
      boxShadow: 'primary',
      transition: '0.5s',
    }}
    padding={3}
  />
))`
  background-color: white;
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
