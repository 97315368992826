import React from 'react';
import styled from '@emotion/styled';
import { Box } from 'rebass';

// eslint-disable-next-line no-unused-vars
const ImageMobile = styled(Box)`
  z-index: 0;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat,no-repeat;
  background-position: center center, center center;
  background-size: cover, cover;
  background-image: url('/samael_27_oct_mobile.jpg');
`;

// eslint-disable-next-line no-unused-vars
const ImageDesktop = styled(Box)`
  z-index: 0;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat,no-repeat;
  background-position: center center, center center;
  background-size: cover, cover;
  background-image: url('/samael_27_oct_desktop.jpg');
`;

const ImageAgeac = styled(Box)`
  z-index: 0;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat,no-repeat;
  background-position: center center, center center;
  background-size: cover, cover;
  background-image: url('/bgimg.jpg');
`;

const BackgroundImage = styled(() => (
  <>
    <Overlay />
    <ImageAgeac />
    {/* <ImageMobile sx={{ display: ['block', null, null, 'none'] }} /> */}
    {/* <ImageDesktop sx={{ display: ['none', null, null, 'block'] }} /> */}
  </>
))``;

// eslint-disable-next-line
const Overlay = styled((props) => <Box {...props} id="Overlay" />)`
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
`;


export default BackgroundImage;
