import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import './index.css';
import App from './containers/App';
// import * as serviceWorker from './serviceWorker';

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
if (!development) {
  Sentry.init({ dsn: 'https://683e48a51245410b9648ea4fa99229b0@o242635.ingest.sentry.io/5297978' });
}

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
