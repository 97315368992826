/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from '@emotion/styled';
import PT from 'prop-types';
import { Box, Flex, Link } from 'rebass';

export default function PlayButton({ isPlaying = false, onClick, ...props }) {
  return (
    <ButtonPlay {...props} onClick={onClick}>
      <DivCircleOutResponsive id="CircleOut">
        <DivCircleInResponsive id="CircleIn">
          {isPlaying ? (
            <DivPause>
              <div />
              <div />
            </DivPause>
          ) : (
            <DivTriangleResponsive />
          )}
        </DivCircleInResponsive>
      </DivCircleOutResponsive>
    </ButtonPlay>
  );
}

PlayButton.propTypes = {
  isPlaying: PT.bool.isRequired,
  onClick: PT.func.isRequired,
};


const ButtonPlay = styled((props) => (
  <Flex justifyContent="center">
    <Link {...props} />
  </Flex>
))`
  display: block;

  &:hover {
    div:first-of-type {
      background-color: ${(p) => p.theme.colors.secondary};
      div {
        background-color: #6a1a21;
        // background-color: #b2a856;
        div {
          border-left-color: ${(p) => p.theme.colors.secondary};
          div {
            background-color: ${(p) => p.theme.colors.secondary};
          }
        }
      }
    }
  }
`;

const DivCircleOutResponsive = (props) => (
  <Flex
    {...props}
    justifyContent="center"
    alignItems="center"
    width={['40px']}
    height={['40px']}
    backgroundColor="white"
    sx={{
      cursor: 'pointer',
      borderRadius: '50%',
      transition: '0.5s',
    }}
  />
);

const DivCircleInResponsive = (props) => (
  <Flex
    {...props}
    justifyContent="center"
    alignItems="center"
    width={['34px']}
    height={['34px']}
    backgroundColor="#6a1a21"
    // backgroundColor="#b2a856"
    sx={{
      borderRadius: '50%',
      transition: '0.5s',
    }}
  />
);

const borderLeftStyles = (size) => `${size}px solid white`;
const borderYStyles = (size) => `${size}px solid transparent`;

const DivTriangleResponsive = (props) => (
  <Box
    {...props}
    width={0}
    height={0}
    ml={['6px']}
    sx={{
      transition: '0.5s',
      borderLeft: [borderLeftStyles(16)],
      borderTop: [borderYStyles(8)],
      borderBottom: [borderYStyles(8)],
    }}
  />
);

const DivPauseResponsive = (props) => (
  <Box
    {...props}
    height={['16px']}
    sx={{
      div: {
        float: 'left',
        width: ['3px'],
        height: '100%',
        background: 'white',
        transition: '0.5s',
        '&:first-of-type': {
          marginRight: ['4px'],
        },
      },
    }}
  />
);

const DivPause = DivPauseResponsive;
